<template>
  <el-dialog
    :title="(currentData.isAdd ? '新增' : '编辑') + '客户端'"
    :visible.sync="currentData.isShow"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="newData" :rules="rules" label-width="100px">
      <el-form-item label="客户端Id" prop="clientId">
        <el-input
          v-model="newData.clientId"
          :disabled="!currentData.isAdd"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户端安全码" prop="clientSecret">
        <el-input v-model="newData.clientSecret"></el-input>
      </el-form-item>
      <el-form-item label="客户端名称" prop="clientName">
        <el-input v-model="newData.clientName"></el-input>
      </el-form-item>
      <el-form-item label="前/后台标识" prop="frontBackSign">
        <el-radio-group v-model="newData.frontBackSign">
          <el-radio
            v-for="(value, name) in enableData.frontBackSign"
            :label="name"
            :key="name"
            >{{ value }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="创建时刻">
        <el-date-picker
          v-model="newData.clientIdIssuedAt"
          disabled
          type="datetime"
          placeholder="选择日期时间"
        />
      </el-form-item>
      <el-form-item label="有效期至">
        <el-date-picker
          v-model="newData.clientSecretExpiresAt"
          type="datetime"
          placeholder="选择过期时刻"
        />
      </el-form-item>

      <el-form-item label="授权类型" prop="authorizationGrantTypes">
        <el-select
          v-model="newData.authorizationGrantTypes"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="(value, name) in enableData.authorizationGrantTypes"
            :key="name"
            :value="name"
            :label="value + '(' + name + ')'"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="认证方法" prop="clientAuthenticationMethods">
        <el-select
          v-model="newData.clientAuthenticationMethods"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="(value, name) in enableData.clientAuthenticationMethods"
            :key="name"
            :value="name"
            :label="value + '(' + name + ')'"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="授权范围" prop="scopes">
        <el-checkbox-group v-model="newData.scopes">
          <el-checkbox
            v-for="item in enableData.scopes"
            :key="item"
            :label="item"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="重定向地址" prop="redirectUris">
        <el-input
          v-if="newData.redirectUris.length == 0"
          v-model="newData.redirectUris[0]"
          placeholder="请输入内容"
        >
          <el-button
            slot="append"
            icon="el-icon-plus"
            @click="uriInputHander(0)"
          ></el-button>
        </el-input>

        <el-input
          v-else
          v-for="(item, index) in newData.redirectUris"
          :key="index"
          v-model="newData.redirectUris[index]"
          placeholder="请输入内容"
        >
          <el-button
            slot="append"
            :icon="index == 0 ? 'el-icon-plus' : 'el-icon-minus'"
            @click="uriInputHander(index)"
          ></el-button>
        </el-input>
      </el-form-item>

      <el-descriptions title="令牌设置：" :column="1" border size="small">
        <el-descriptions-item label="访问令牌格式">
          <el-radio-group
            v-if="newData.tokenSettings"
            v-model="newData.tokenSettings.accessTokenFormat"
          >
            <el-radio
              v-for="(value, name) in enableData.tokenSettings
                .accessTokenFormat"
              :label="name == 'self_contained' ? 'self-contained' : name"
              :key="name"
              >{{ value }}</el-radio
            >
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="访问令牌时长（分钟）"
          ><el-input-number
            v-if="newData.tokenSettings"
            v-model="newData.tokenSettings.accessTokenTimeToLive"
        /></el-descriptions-item>
        <el-descriptions-item label="id令牌算法">
          <el-select
            v-if="newData.tokenSettings"
            v-model="newData.tokenSettings.idTokenSignatureAlgorithm"
            placeholder="请选择"
          >
            <el-option
              v-for="item in enableData.tokenSettings.idTokenSignatureAlgorithm"
              :key="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="重用刷新令牌">
          <el-select
            v-if="newData.tokenSettings"
            v-model="newData.tokenSettings.reuseRefreshTokens"
            placeholder="请选择"
          >
            <el-option :value="true" label="true"></el-option>
            <el-option :value="false" label="false"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="刷新令牌时长（分钟）"
          ><el-input-number
            v-if="newData.tokenSettings"
            v-model="newData.tokenSettings.refreshTokenTimeToLive"
        /></el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="客户端设置：" :column="1" border size="small">
        <el-descriptions-item label="需要用户同意">
          <el-select
            v-if="newData.clientSettings"
            v-model="newData.clientSettings.requireAuthorizationConsent"
            placeholder="请选择"
          >
            <el-option :value="true" label="true"></el-option>
            <el-option :value="false" label="false"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="token授权算法">
          <el-select
            v-if="newData.clientSettings"
            v-model="
              newData.clientSettings.tokenEndpointAuthenticationSigningAlgorithm
            "
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in enableData.clientSettings
                .tokenEndpointAuthenticationSigningAlgorithm"
              :key="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="证明密钥质询">
          <el-select
            v-if="newData.clientSettings"
            v-model="newData.clientSettings.requireProofKey"
            placeholder="请选择"
          >
            <el-option :value="true" label="true"></el-option>
            <el-option :value="false" label="false"></el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="jwt地址"
          ><el-input
            v-if="newData.clientSettings"
            v-model="newData.clientSettings.jwkSetUrl"
            placeholder="请输入内容"
        /></el-descriptions-item>
      </el-descriptions>

      <div class="el-descriptions__header">
        <div class="el-descriptions__title">备注说明：</div>
        <div class="el-descriptions__extra"></div>
      </div>
      <el-input type="textarea" v-model="newData.remarks"></el-input>

      <div class="el-dialog__footer">
        <span class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="save">保 存</el-button>
        </span>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
    enableData: {
      type: Object,
    },
    currentData: {
      type: Object,
    },
  },

  data() {
    return {
      newData: this.currentData.currentRow,
      rules: {
        clientId: [
          { required: true, message: "请输入客户端id", trigger: "blur" },
        ],
        clientName: [
          { required: true, message: "请输入客户端名称", trigger: "blur" },
        ],
        clientSecret: [
          { required: true, message: "请输入安全码", trigger: "blur" },
        ],
        frontBackSign: [
          { required: true, message: "前后台必选", trigger: "blur" },
        ],
        clientAuthenticationMethods: [
          { required: true, message: "授权方法必选", trigger: "blur" },
        ],
        authorizationGrantTypes: [
          { required: true, message: "授权类型必选", trigger: "blur" },
        ],
        redirectUris: [
          //   { required: true, message: "请输入有效重定向地址", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              const hasAuthorizationCode =
                this.newData.authorizationGrantTypes.indexOf(
                  "authorization_code"
                );
              let size = 0;
              for (let index = 0; index < value.length; index++) {
                const url = value[index];
                if (hasAuthorizationCode > 0 && url != null) {
                  size++;
                  if (url.match("^http[s]*.+") == null) {
                    return callback(
                      new Error(
                        "地址：" + url + "不合规，需要一个有效的有效重定向地址"
                      )
                    );
                  }
                }
              }
              if (hasAuthorizationCode > 0 && size == 0) {
                return callback(
                  new Error("授权码模式需要至少一项有效重定向地址")
                );
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        scopes: [{ required: true, message: "授权范围必选", trigger: "blur" }],
      },
    };
  },

  methods: {
    uriInputHander(index) {
      const redirectUris = this.newData.redirectUris;
      if (index == 0) {
        if (redirectUris.length == 0) {
          redirectUris.push(null);
        }
        redirectUris.push(null);
      } else {
        this.newData.redirectUris.splice(index, 1);
      }
    },
    save() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        this.$emit("save");
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
