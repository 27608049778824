<template>
  <common-layout>
    <template #header>
      <el-form inline>
        <el-form-item label="前/后台标识">
          <el-select
            v-model="search.frontBackSign"
            clearable
            @change="handleQuery"
          >
            <el-option
              v-for="(value, name) in $options.enableData.frontBackSign"
              :label="value"
              :value="name"
              :key="name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查 询</el-button>
          <el-button type="primary" plain @click="openDialog(true, {})"
            >新 增</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <template #default>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="clientId" label="客户端Id" width="80" />
        <el-table-column prop="clientSecret" label="客户端安全码" width="120" />
        <el-table-column prop="clientName" label="客户端名称" width="120" />
        <el-table-column
          prop="frontBackSign"
          label="前后台标识"
          width="120"
          :formatter="
            (row, column, cellValue, index) => {
              return (
                $options.enableData.frontBackSign[cellValue] +
                '(' +
                cellValue +
                ')'
              );
            }
          "
        />

        <el-table-column
          prop="authorizationGrantTypes"
          label="授权类型"
          width="160"
        >
          <template #default="{ row, column }">
            <div v-for="item in row[column.property]" :key="item">
              <el-tag type="success">{{
                $options.enableData.authorizationGrantTypes[item] +
                "(" +
                item +
                ")"
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="clientAuthenticationMethods"
          label="认证方法"
          width="120"
        >
          <template #default="{ row, column }">
            <div v-for="item in row[column.property]" :key="item">
              <el-tag type="success">{{
                $options.enableData.clientAuthenticationMethods[item] +
                "(" +
                item +
                ")"
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="scopes" label="授权范围" width="100">
          <template #default="{ row, column }">
            <div v-for="item in row[column.property]" :key="item">
              <el-tag type="success">{{ item }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="redirectUris" label="重定向地址" width="150">
          <template #default="{ row, column }">
            <el-link
              v-for="item in row[column.property]"
              :key="item"
              type="primary"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="bottom"
              >
                <el-tag type="success">{{ item }}</el-tag>
              </el-tooltip></el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="tokenSettings" label="token设置" width="240">
          <template #default="{ row, column }">
            <el-descriptions :column="1" border size="small">
              <el-descriptions-item label="访问令牌格式">{{
                row[column.property].accessTokenFormat
              }}</el-descriptions-item>
              <el-descriptions-item label="访问令牌时长（分钟）">{{
                row[column.property].accessTokenTimeToLive
              }}</el-descriptions-item>
              <el-descriptions-item label="id令牌算法">{{
                row[column.property].idTokenSignatureAlgorithm
              }}</el-descriptions-item>
              <el-descriptions-item label="重用刷新令牌">{{
                row[column.property].reuseRefreshTokens
              }}</el-descriptions-item>
              <el-descriptions-item label="刷新令牌时长（分钟）">{{
                row[column.property].refreshTokenTimeToLive
              }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>

        <el-table-column prop="clientSettings" label="客户端设置" width="180">
          <template #default="{ row, column }">
            <el-descriptions :column="1" border size="small">
              <el-descriptions-item label="用户参与授权">{{
                row[column.property].requireAuthorizationConsent
              }}</el-descriptions-item>
              <el-descriptions-item label="token授权算法">{{
                row[column.property].tokenEndpointAuthenticationSigningAlgorithm
              }}</el-descriptions-item>
              <el-descriptions-item label="证明密钥质询">{{
                row[column.property].requireProofKey
              }}</el-descriptions-item>
              <el-descriptions-item label="jwt设置地址">{{
                row[column.property].jwkSetUrl
              }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>

        <el-table-column prop="clientIdIssuedAt" label="创建时刻" width="140" />
        <el-table-column
          prop="clientSecretExpiresAt"
          label="有效期至"
          width="140"
        />
        <el-table-column prop="remarks" label="说明备注" width="140" />

        <el-table-column prop="createUser" label="创建人" width="80" />
        <el-table-column prop="updateUser" label="更新人" width="80" />
        <el-table-column prop="updateTime" label="更新时间" width="140" />

        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="openDialog(false, scope.row)"
              type="text"
              size="small"
            >
              编辑</el-button
            >
            <el-button
              @click.native.prevent="deleteRow(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <modify
        v-if="currentData.isShow"
        :enableData="$options.enableData"
        :currentData="currentData"
        @save="save"
        @close="close"
      ></modify>
    </template>
    <template #footer> </template>
  </common-layout>
</template>

<script>
import api from "@/api/oauth/oauth";
import Modify from "./Modify.vue";

export default {
  components: { Modify },
  enableData: {
    //元信息
    frontBackSign: { front_desk: "前台", back_end: "后台" },
    authorizationGrantTypes: {
      authorization_code: "授权码",
      implicit: "隐藏式",
      refresh_token: "令牌刷新",
      client_credentials: "客户端凭证",
      password: "密码式",
    },
    clientAuthenticationMethods: {
      client_secret_basic: "basic方法头",
      client_secret_post: "请求体",
      client_secret_jwt: "",
      private_key_jwt: "",
      none: "无",
    },
    scopes: ["user_info", "openid", "profile", "email"],
    tokenSettings: {
      accessTokenFormat: { self_contained: "jwt令牌", reference: "隐式令牌" }, //self-contained 例外处理
      idTokenSignatureAlgorithm: [
        "RS256",
        "RS384",
        "RS512",
        "ES256",
        "ES384",
        "ES512",
        "PS256",
        "PS384",
        "PS512",
      ],
    },
    clientSettings: {
      tokenEndpointAuthenticationSigningAlgorithm: [
        "RS256",
        "RS384",
        "RS512",
        "ES256",
        "ES384",
        "ES512",
        "PS256",
        "PS384",
        "PS512",
      ],
    },
  },
  defaultData: {
    clientAuthenticationMethods: ["client_secret_post", "client_secret_basic"],
    authorizationGrantTypes: ["refresh_token", "authorization_code"],
    frontBackSign: "front_desk",
    scopes: ["user_info", "openid", "profile", "email"],
    redirectUris: [],
    clientSettings: {
      // tokenEndpointAuthenticationSigningAlgorithm: null,
      // requireProofKey: false,
      requireAuthorizationConsent: false,
      // jwkSetUrl: null
    },
    tokenSettings: {
      accessTokenFormat: "reference",
      // accessTokenTimeToLive: 5,
      // idTokenSignatureAlgorithm: "RS256",
      reuseRefreshTokens: true,
      // refreshTokenTimeToLive: 60
    },
  },

  data() {
    return {
      search: { frontBackSign: null },
      tableData: [],

      currentData: {
        isShow: false,
        isAdd: false,
        currentRow: {},
      },
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      api.getList(this.search).then((re) => {
        this.tableData = re.data;
      });
    },
    openDialog(isAdd, row) {
      let data = {
        isShow: true,
        isAdd: isAdd,
        currentRow: null,
      };
      if (isAdd) {
        data.currentRow = JSON.parse(JSON.stringify(this.$options.defaultData));
        this.currentData = data;
      } else {
        api.findById({ id: row.id }).then((re) => {
          data.currentRow = re.data;
          this.currentData = data;
        });
      }
    },

    save() {
      const currentRow = [];
      this.currentData.currentRow.redirectUris.forEach((uri) => {
        if (uri != null && uri.replaceAll(" ", "") != "") {
          currentRow.push(uri);
        }
      });
      this.currentData.currentRow.redirectUris = currentRow;
      if (this.currentData.isAdd) {
        api.add(this.currentData.currentRow).then(() => {
          this.$msg.success("操作成功");
          this.close();
        });
      } else {
        api.update(this.currentData.currentRow).then(() => {
          this.$msg.success("操作成功");
          this.close();
        });
      }
    },
    close() {
      this.currentData = {
        isShow: false,
        isAdd: null,
        currentRow: {},
      };
      this.handleQuery();
    },

    deleteRow(row) {
      api.remove({ id: row.id }).then(() => {
        this.$msg.success("操作成功");
        this.handleQuery();
      });
    },
  },
};
</script>
